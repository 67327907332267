.ui--header {
  @apply px-4 pt-4 max-sm:px-2 max-sm:pt-2;

  &-container {
    @apply grid h-16 grid-cols-[1fr_2fr_1fr] gap-4 rounded-2xl bg-theme-dark px-4;
    @apply max-lg:grid-flow-col max-lg:grid-cols-none max-lg:justify-between max-lg:gap-2 max-sm:h-14 max-sm:grid-flow-col max-sm:grid-cols-none max-sm:justify-between;

    &.fns {
      @apply flex justify-between;
    }

    .columns {
      &-end {
        @apply grid grid-flow-col justify-center gap-4 max-sm:gap-2;

        &.is-acc {
          @apply max-sm:hidden;
        }
      }
    }

    .logo {
      @apply h-12 object-contain object-center max-sm:h-10 max-sm:w-auto;
    }

    .btn {
      &-menu {
        @apply px-2;
      }

      &-menudar {
        @apply p-1 sm:hidden;
      }
    }
  }
}
