.ui--navigator {
  @apply fixed inset-x-4 bottom-2 z-20 flex justify-center max-sm:inset-x-2;

  &:not(.is-auth) {
    @apply md:hidden;
  }

  &-container {
    @apply h-16 w-full rounded-xl bg-[#052358] ring-1 ring-[#29252E];
    background-image: linear-gradient(to top, #052358 0%, #0c4cbe 100%);
    max-width: 480px;
  }

  &-menu {
    @apply grid grid-cols-5;

    .li {
      @apply flex justify-center;
    }

    .router-link {
      @apply h-16 px-2 py-3;

      &.center {
        @apply relative pt-[29px];

        .icon {
          @apply absolute bottom-9;
        }
      }

      .icon-svg {
        @apply mx-auto size-5 stroke-white text-transparent;
      }

      .icon {
        @apply mx-auto;
      }

      .text {
        @apply text-nowrap text-sm text-white max-sm:text-xs;

        &.sm {
          @apply max-sm:text-sm;
        }
      }
    }
  }
}
