.ui--guard {
  @apply flex items-center justify-center p-4;
  min-height: calc(100vh - 128px);

  &-context {
    @apply relative mx-auto w-full justify-center overflow-hidden rounded-3xl bg-theme-dark px-8 py-12;
    max-width: 480px;
  }

  &-header {
    @apply mb-10 grid gap-6;

    .logo {
      @apply mx-auto object-contain object-center;
    }

    .h2 {
      @apply text-3xl font-bold;
    }
  }

  &-body {
  }

  &-form {
    @apply mx-auto w-full max-w-96;

    .bank {
      .text-2 {
        @apply text-black;
      }
    }

    .btn {
      @apply h-12;

      &-submit {
        @apply w-full;
      }
    }
  }

  &-step {
    @apply grid grid-cols-3 gap-4;
  }
}
